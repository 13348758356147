import React from "react";

const Footer = () => (
  <footer className="bg-dark p-3 text-center">
    <br />
    <p>
      Copyright © 2024 embedora.com - All Rights Reserved.
    </p>
  </footer>
);

export default Footer;
