import React from "react";
//    
const TextBody = () => (
  <div className="text-center hero my-4">
    <p className="lead sentient-font">
      <b className="sentient-bold">Directory Maps </b> are for those who want to make informed decisions, without being overwhelmed by information. <b className="sentient-bold">Directory Maps</b> are for those who value autonomy and want to discover things on their own terms, not just what algorithms feed them. <b className="sentient-bold">Directory Maps</b> are for the curious. Those who are always looking for opportunities that others might miss. <b className="sentient-bold">Directory Maps</b> are for those who are tired of traditional, linear ways of finding information; those who crave dynamic and interactive experiences. <b className="sentient-bold">Directory Maps</b> are for those who are brave enough to venture into unfamiliar spaces.  
    </p>
    <h2 className="mb-4 sentient-heading">If you're interested in Directory Maps</h2>
    <p className="lead sentient-font">
      <b className="sentient-bold">Try it out:</b> Unicorns is a Directory Map of the world's 1500 startup unicorns, private companies with valuations over a billion dollars. When the Directory Map loads, the controls are simple, just pan and zoom. <em> Technical Note: Directory Maps have not yet been optimized for mobile. For the best experience, use Safari for Mac and iPad & use Chrome for PC.</em> 
    </p>
    <h4 className="mb-4 sentient-heading"><a href="/directories/unicorns.html">
          Test drive the Unicorns Directory Map.
        </a></h4> 
    <p className="lead sentient-font">
      <b className="sentient-bold">Provide feedback and advice</b> by participating in a 30-minute user experience research session.
    </p>
    <h4 className="mb-4 sentient-heading"><a href="https://forms.office.com/r/iM7nmtaMuE">
        Fill out the form.
        </a></h4>
    <p className="lead sentient-font">
      <b className="sentient-bold">Propose</b> a Directory Map pilot, intergration, or partnership by contacting our founder at 
    </p>
    <h4 className="mb-4 sentient-heading">thomas@embedora.com</h4>
  </div>
);

export default TextBody;
