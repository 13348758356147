import React from "react";

import logo from "../assets/icon_white.svg";
//    
const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src={logo} alt="Embedora logo" width="120" />
    <h1 className="mb-4 hero-title">ALPHABETICAL ORDER IS BORING</h1>
    <p className="lead sentient-font">
      <b className="sentient-bold">Directory Maps</b> offer a better way to explore opportunities.
    </p>
    <p className="lead sentient-font">
      We take existing directories of companies, people, and products and transform them into engaging dynamic maps made available on a website or on-site touchscreen kiosk. Unlike alphabetical lists and search bars, they are designed for <b>serendipity</b> instead of precise querying. Unlike recommender systems and matchmaking systems, they don't require users to fill out interest forms and profiles. <b className="sentient-bold">Directory Maps</b> are changing the way people explore and encounter exhibitors, speakers, products, and each other.
    </p>
    <p className="lead sentient-font">
      <b className="sentient-bold">Directory Maps</b> are briefly demoed in the video below.
    </p>
  </div>
);

export default Hero;
