import React, { Fragment } from "react";

import Hero from "../components/Hero";
import YouTubeVideo from "../components/YouTubeVideo";
import TextBody from "../components/TextBody";

const Home = () => (
  <Fragment>
    <Hero />
    <YouTubeVideo />
    <hr />
    <TextBody />
  </Fragment>
);

export default Home;
